import { ComponentPropsWithoutRef } from 'react';

import classNames from 'classnames';

export interface CollapseProps extends ComponentPropsWithoutRef<'div'> {
  isOpen: boolean;
  isHorizontal?: boolean;
  wrapperBoxProps?: ComponentPropsWithoutRef<'div'>;
}

export default function Collapse({
  isOpen = false,
  children,
  className,
  isHorizontal = false,
  wrapperBoxProps,
  ...props
}: CollapseProps) {
  return (
    <div
      className={classNames(
        'collapsible',
        {
          'collapsible-horizontal': isHorizontal,
          'collapsible-open': isOpen,
        },
        className,
      )}
      {...props}
    >
      <div {...wrapperBoxProps}>{children}</div>
    </div>
  );
}
