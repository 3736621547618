'use client';

import { FloatingTree, useFloatingParentNodeId } from '@floating-ui/react';

import DropdownMenu, { DropdownMenuProps } from './DropdownMenu';

export type DropdownProps = DropdownMenuProps;

const Dropdown = (props: DropdownProps) => {
  const parentId = useFloatingParentNodeId();

  if (!parentId) {
    return (
      <FloatingTree>
        <DropdownMenu {...props} />
      </FloatingTree>
    );
  }

  return <DropdownMenu {...props} />;
};

export default Dropdown;
