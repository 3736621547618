import { MouseEventHandler, ReactNode } from 'react';

import classNames from 'classnames';

import {
  Button,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalDescription,
  ModalHeading,
  Spinner,
  useModalController,
} from '@uikit';

export interface ConfirmModalProps {
  heading?: ReactNode;
  description?: ReactNode;
  trigger?: ReactNode;
  showDefaultTriggerButton?: boolean;
  showCloseIcon?: boolean;
  showCancelButton?: boolean;
  isConfirming?: boolean;
  confirmText?: ReactNode;
  cancelText?: ReactNode;
  onConfirm?: MouseEventHandler<HTMLButtonElement>;
  onCancel?: MouseEventHandler<HTMLButtonElement>;
  customActions?: ReactNode[];
  roundedButtons?: boolean;
  classnames?: {
    heading?: string;
    description?: string;
    actions?: string;
    modal?: string;
    overlay?: string;
  };
  controller: ReturnType<typeof useModalController>;
}

export default function ConfirmModal({
  heading = 'Confirmation Modal',
  description = 'Are you sure?',
  showCloseIcon = true,
  showCancelButton = true,
  onConfirm,
  onCancel,
  isConfirming,
  confirmText = 'Confirm',
  cancelText = 'Cancel',
  customActions,
  roundedButtons,
  classnames = {},
  controller,
}: ConfirmModalProps) {
  return (
    <Modal {...controller.props}>
      <ModalContent
        className={classNames(
          'w-full min-w-[280px] max-w-[600px] max-sm:mb-0 flex flex-col',
          classnames.modal,
        )}
        overlayClassName={classNames(classnames.overlay)}
        data-qa="warning-modal-window"
      >
        {showCloseIcon && <ModalCloseButton />}

        <ModalHeading className={classNames('mb-3', classnames.heading)}>{heading}</ModalHeading>

        {description && (
          <ModalDescription
            className={classNames('break-words', classnames.description)}
            data-qa="warning-modal-text"
          >
            {description}
          </ModalDescription>
        )}

        <div className={classNames('mt-5 flex flex-wrap gap-2.5', classnames.actions)}>
          {customActions?.map((action) => action)}

          {!customActions && (
            <>
              {showCancelButton && (
                <Button
                  variant="outline"
                  className="min-h-[45px] flex-1 whitespace-nowrap"
                  onClick={(event) => {
                    controller.close();
                    onCancel?.(event);
                  }}
                  rounded={roundedButtons}
                  data-qa="warning-modal-cancel-button"
                >
                  {cancelText}
                </Button>
              )}

              <Button
                variant="primary"
                className="min-h-[45px] flex-1 whitespace-nowrap"
                onClick={onConfirm}
                rounded={roundedButtons}
                disabled={isConfirming}
                data-qa="warning-modal-ok-button"
              >
                {isConfirming ? <Spinner size="sm" color="light" /> : confirmText}
              </Button>
            </>
          )}
        </div>
      </ModalContent>
    </Modal>
  );
}
