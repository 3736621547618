'use client';

import { ElementType, FocusEvent, forwardRef, MouseEvent, Ref } from 'react';

import { MenuListItem, MenuListItemProps } from '@uikit/components/MenuList';

import { useFloatingTree, useListItem, useMergeRefs } from '@floating-ui/react';

import { useDropdownMenu } from './DropdownMenu';

type DropdownItemProps<T extends ElementType> = MenuListItemProps<T> & {
  label: string;
  disabled?: boolean;
};

export const DropdownItem = <T extends ElementType = 'button'>(
  { label, disabled, children, ...props }: DropdownItemProps<T>,
  ref: Ref<HTMLButtonElement>,
) => {
  const menu = useDropdownMenu();
  const item = useListItem({ label: disabled ? null : label });
  const tree = useFloatingTree();
  const isActive = item.index === menu?.activeIndex;

  return (
    <MenuListItem
      {...props}
      ref={useMergeRefs([item.ref, ref])}
      role="menuitem"
      tabIndex={isActive ? 0 : -1}
      {...menu?.getItemProps({
        onClick(event: MouseEvent<HTMLButtonElement>) {
          props.onClick?.(event);
          tree?.events.emit('click');
        },
        onFocus(event: FocusEvent<HTMLButtonElement>) {
          props.onFocus?.(event);
        },
      })}
    >
      {children || label}
    </MenuListItem>
  );
};

export default forwardRef(DropdownItem) as <C extends ElementType>(
  props: DropdownItemProps<C>,
) => JSX.Element;
