import React, { ComponentPropsWithoutRef } from 'react';

import classNames from 'classnames';

import { useFormControl } from './FormControl';

type FormControlHelperTextProps = ComponentPropsWithoutRef<'p'>;

const FormControlHelperText = ({ className, ...props }: FormControlHelperTextProps) => {
  const { isError, isSuccess, disabled } = useFormControl();

  return (
    <p
      className={classNames(
        'form-control-helper-text',
        {
          'form-control-helper-text-error': isError,
          'form-control-helper-text-success': isSuccess,
          'form-control-helper-text-disabled': disabled,
        },
        className,
      )}
      role={isError ? 'alert' : ''}
      {...props}
    />
  );
};

export default FormControlHelperText;
