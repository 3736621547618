import { ComponentPropsWithoutRef, forwardRef, Ref } from 'react';

import classNames from 'classnames';

export type MenuListProps = ComponentPropsWithoutRef<'div'> & {
  variant?: 'primary' | 'secondary';
};

const MenuList = ({ className, variant, ...props }: MenuListProps, ref: Ref<HTMLDivElement>) => {
  return (
    <div
      ref={ref}
      className={classNames(variant === 'secondary' ? 'select-list' : 'menu-list', className)}
      role="menu"
      {...props}
    />
  );
};

export default forwardRef(MenuList);
