import { ComponentPropsWithoutRef, ElementType, forwardRef, Ref } from 'react';

import classNames from 'classnames';

export type MenuListItemProps<T extends ElementType> = {
  isActive?: boolean;
  Component?: T;
  variant?: 'primary' | 'secondary';
} & ComponentPropsWithoutRef<T>;

const MenuListItem = <T extends ElementType>(
  { className, Component, variant, isActive, ...props }: MenuListItemProps<T>,
  ref: Ref<HTMLElement>,
) => {
  const As = Component || 'button';

  return (
    <As
      ref={ref}
      {...(Component === 'button' && {
        type: 'button',
      })}
      className={classNames(
        variant === 'secondary'
          ? {
              'select-list-item': true,
              'select-list-item-text-active': isActive,
            }
          : {
              'menu-list-item': true,
              'menu-list-item-active': isActive,
            },
        className,
      )}
      role="menuitem"
      tabIndex={0}
      {...(props as any)}
      data-qa="menu-list-item"
    />
  );
};

export default forwardRef(MenuListItem);
