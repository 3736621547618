import React, { ComponentPropsWithoutRef, SVGAttributes } from 'react';

export interface DashedBorderProps extends ComponentPropsWithoutRef<'svg'> {
  borderRadius?: SVGAttributes<'rect'>['rx'];
}

const DashedBorder = ({
  borderRadius,
  strokeDasharray = '4, 4',
  strokeLinecap = 'round',
  strokeWidth = 1,
  strokeDashoffset = 6,
  stroke = 'currentColor',
  ...props
}: DashedBorderProps) => {
  return (
    <svg
      width="100%"
      height="100%"
      fill="none"
      strokeDasharray={strokeDasharray}
      strokeLinecap={strokeLinecap}
      strokeWidth={strokeWidth}
      strokeDashoffset={strokeDashoffset}
      stroke={stroke}
      {...props}
    >
      <rect width="100%" height="100%" rx={borderRadius} ry={borderRadius} />
    </svg>
  );
};

export default DashedBorder;
