import { ComponentPropsWithoutRef, ReactNode } from 'react';

import classNames from 'classnames';

import { useAccordion } from '@uikit/components/Accordion/Accordion';
import AngleDown from '@uikit/icons/AngleDown';

interface AccordionSummaryProps extends ComponentPropsWithoutRef<'button'> {
  expandIcon?: ReactNode;
  collapseIcon?: ReactNode;
  iconClassName?: string;
  iconExpandedClassName?: string;
}

export default function AccordionSummary({
  expandIcon = <AngleDown />,
  collapseIcon = null,
  children,
  className,
  iconClassName,
  iconExpandedClassName,
  ...props
}: AccordionSummaryProps) {
  const { expanded, toggle } = useAccordion();

  return (
    <button
      onClick={toggle}
      className={classNames('flex items-center justify-between gap-1', className)}
      {...props}
    >
      {children}
      {expandIcon !== null && (
        <div
          className={classNames('icon-sm transition', iconClassName, {
            [iconExpandedClassName ? iconExpandedClassName : 'rotate-180']: expanded,
          })}
        >
          {collapseIcon && expanded ? collapseIcon : expandIcon}
        </div>
      )}
    </button>
  );
}
