'use client';

import { ComponentPropsWithoutRef, forwardRef, Ref } from 'react';

import classNames from 'classnames';

export interface InputProps extends ComponentPropsWithoutRef<'input'> {
  shape?: 'pill';
  isError?: boolean;
  isSuccess?: boolean;
}

const Input = (
  { type = 'text', shape, isError, isSuccess, className, ...props }: InputProps,
  ref: Ref<HTMLInputElement>,
) => {
  return (
    <input
      type={type}
      className={classNames('text-field', className, {
        'text-field-success': isSuccess,
        'text-field-error': isError,
        'text-field-shape-pill': shape === 'pill',
      })}
      ref={ref}
      {...props}
    />
  );
};

export default forwardRef(Input);
