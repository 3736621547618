import React, { ComponentPropsWithoutRef } from 'react';

import classNames from 'classnames';

import { useFormControl } from './FormControl';

export type FormControlLabelProps = ComponentPropsWithoutRef<'label'>;

export const FormControlLabel = ({ id, className, children, ...props }: FormControlLabelProps) => {
  const {
    id: formControlId,
    isError,
    isSuccess,
    disabled,
    required,
    visuallyRequired,
  } = useFormControl();
  const actualId = formControlId || id;

  return (
    <label
      className={classNames(
        'form-control-label',
        {
          'form-control-label-error': isError,
          'form-control-label-success': isSuccess,
          'form-control-label-disabled': disabled,
        },
        className,
      )}
      htmlFor={actualId}
      {...props}
    >
      {(required || visuallyRequired) && (
        <span aria-hidden="true" className="form-control-label-required">
          *&nbsp;
        </span>
      )}
      {children}
    </label>
  );
};

export default FormControlLabel;
