import { ComponentPropsWithoutRef, ForwardedRef, forwardRef } from 'react';

import classNames from 'classnames';

export interface CardProps extends ComponentPropsWithoutRef<'div'> {
  variant?: 'bordered' | 'shadowed';
  isDefaultPadding?: boolean;
}

const Card = (
  { variant = 'shadowed', isDefaultPadding = true, className, children, ...props }: CardProps,
  ref: ForwardedRef<HTMLDivElement>,
): JSX.Element => {
  return (
    <div
      ref={ref}
      className={classNames(
        `card rounded-2xl`,
        {
          'shadow-main': variant === 'shadowed',
          border: variant === 'bordered',
          'border-old-dark-10': variant === 'bordered',
          'p-5': isDefaultPadding,
        },
        className,
      )}
      {...props}
      data-qa="hot-offer-container"
    >
      {children}
    </div>
  );
};

export default forwardRef(Card);
